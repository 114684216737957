import React,{useRef
} from 'react'
import one from './back.png'
import two from './live.svg'
import six from './SCROLL DOWN.png'

import five from './5.svg'
import Down from './down.png'

export default function Footer() {
    const scrollToRef = () => window.scrollTo(0, window.innerHeight)   
 
  

    return (
<>  

        <div style={{padding:"0rem 2rem",marginTop:'-5rem'}} id="navba1" href="#about-us-section"
        class="scroll-button smoothscroll paddingLobby 

        
        dn-M" >
        
        <div class="row   fotterPadding" >
        
     


  

     
        
   
       
        
     





   

  
        
          </div>
        
        
        
        
        
        
        
        
        </div> 
        </>
    )
}
