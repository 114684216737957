import React, {Component,useEffect} from 'react'
import ReactPlayer from 'react-player/lazy'
// import "node_modules/video-react/dist/video-react.css";
import { Player } from 'video-react';
import classes from './k.css'
import Footer from './footer'
export default function Lobby() {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    }
  }, [])


  return (
  


      <div style={{background:'darkblue',height:'110vh'}} >
     
  

    

  
  <iframe style={{marginTop:"-5rem"}} src="https://player.vimeo.com/video/496386071?title=0&byline=0&portrait=0&autoplay=1" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

 

 
<Footer></Footer>


  </div>
  )
}
class Example6 extends Component {
  constructor (props) {
      super(props);

      this.state = {
          videoURL: 'https://player.vimeo.com/video/495470034'
      }
  }

  render () {
      return (
          <video id="background-video" loop autoPlay>
              <source src="https://player.vimeo.com/video/495470034" type="video/mp4" />
           
              Your browser does not support the video tag.
          </video>
      )
  }
};
const Example = () => {
  const videoSource = "https://vimeo.com/496386071"
  return (
      <div className={classes.Container} >
          <video autoPlay="autoplay" loop="loop" muted className={classes.Video} >
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
          </video>

          <div className={classes.Content}>
              <div className={classes.SubContent} >
                  <h1>Reactjs Course</h1>
                  <p>Learn how to develope React projects</p>
                  <button type="button" className="btn btn-outline-dark">View the course</button>
                  <img
                      src="https://images.unsplash.com/photo-1518806118471-f28b20a1d79d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
                      alt="profile" />
              </div>
          </div>
      </div>
  )
}




